<template>
  <a-card
    :editable="false"
    :header="$t(header)"
  >
    <contact
      v-for="contact in collectionObject"
      :key="contact.id"
      :contact="contact"
      :collection-path="collectionPath"
      :options="options"
      :permanent-document="permanentDocument"
    />
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { get } from 'lodash'
import Contact from './Contact'

export default {
  components: {
    Contact
  },
  props: {
    collectionPath: {
      type: String,
      default: 'contacts'
    },
    options: {
      type: Array|Object,
      default: () => []
    },
    permanentDocument: {
      type: Object,
      required: false
    },
    header: {
      type: String,
      default: 'common.contacts'
    }
  },
  computed: {
    collectionObject() {
      return _.get(this.doc, this.collectionPath)
    }
  }
}
</script>
