<template>
  <div class="is-outlined">
    <div class="is-italic">
      <b-icon
        icon="account-circle"
        type="is-primary"
        @click.native="edit"
      />
      {{ contact.name }}
    </div>
    <div><a :href="`mailto:${contact.email}`">{{ contact.email }}</a></div>
    <div><a :href="`tel:${contact.phone}`">{{ contact.phone }}</a></div>
  </div>
</template>

<script>
import Form from '@/components/Contacts/Form'

export default {
  props: {
    contact: {
      required: true
    },
    collectionPath: {
      type: String,
      default: 'contacts'
    },
    options: {
      type: Array|Object,
      default: () => []
    },
    permanentDocument: {
      type: Object,
      required: false
    }
  },
  methods: {
    edit () {
      const { id } = this.contact
      const { collectionPath, options, permanentDocument } = this
      this.$buefy.modal.open({
        parent: this,
        component: Form,
        hasModalCard: true,
        width: 400,
        height: 400,
        props: {
          id,
          collectionPath,
          options,
          permanentDocument
        }
      })
    }
  }
}
</script>
