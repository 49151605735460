<template>
  <a-card
    :style="{ marginTop: '2em' }"
    :editable="false"
  >
    <nav class="panel">
      <depot
        v-for="depot in orderedDepots"
        :key="depot.id"
        :depot="depot"
      />
    </nav>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Depot from './Depot'

export default {
  components: {
    Depot
  },
  computed: {
    orderedDepots () {
      return _.orderBy(this.doc.depots, ['name'], ['asc'])
    }
  }
}
</script>
