<template>
  <div
    class="panel-block"
    @click="edit"
  >
    <span :style="{ marginLeft: '1em'}">
      {{ depot.name }}
    </span>
  </div>
</template>

<script>
import DepotForm from './DepotForm'

export default {
  props: {
    depot: {
      required: true
    }
  },
  methods: {
    edit () {
      const { id } = this.depot
      this.$buefy.modal.open({
        parent: this,
        component: DepotForm,
        hasModalCard: true,
        width: 400,
        height: 400,
        props: {
          id
        }
      })
    }
  }
}
</script>
